.button-reset {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.text-gray {
	color: var(--ct-gray);
}

.btn-action:hover {
	color: var(--ct-primary);
}

.min-h-100vh {
	min-height: 100vh;
}
