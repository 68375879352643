.support-card {
	transition: border-color 250ms;
}
.support-card:hover {
	cursor: pointer;
	border-color: var(--ct-primary) !important;
}

.support-card-image {
	max-width: 40px;
}
