/* Buttons */
.btn-primary {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
  box-shadow: 0 2px 6px 0 hsla(var(--ct-primary-hue), 87%, 70%, 0.5);
}
.btn-primary:hover {
  background-color: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    calc(var(--ct-primary-lightness) + 4%)
  );
  border-color: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    calc(var(--ct-primary-lightness) + 6%)
  );
}
.btn-primary:focus {
  background-color: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    calc(var(--ct-primary-lightness) + 4%)
  );
  border-color: hsl(var(--ct-primary-hue), var(--ct-primary-saturation), 79%);
  -webkit-box-shadow: 0 0 0 0.2rem
    hsla(var(--ct-primary-hue), var(--ct-primary-saturation), 75%, 0.5);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--ct-primary-hue), var(--ct-primary-saturation), 75%, 0.5);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    56%
  );
  border-color: hsl(var(--ct-primary-hue), var(--ct-primary-saturation), 53%);
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-outline-primary:active + .btn-outline-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem
    hsla(var(--ct-primary-hue), var(--ct-primary-saturation), 75%, 0.502);
  box-shadow: 0 0 0 0.2rem
    hsla(var(--ct-primary-hue), var(--ct-primary-saturation), 75%, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    70%
  );
  border-color: hsl(var(--ct-primary-hue), var(--ct-primary-saturation), 70%);
}

.btn-outline-primary {
  color: var(--ct-primary);
  border-color: var(--ct-primary);
}
.btn-outline-primary:hover {
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
}
.btn-secondary {
  background-color: var(--ct-secondary);
  border-color: var(--ct-secondary);
}
.btn-secondary:hover {
  background-color: hsl(
    var(--ct-secondary-hue),
    var(--ct-secondary-saturation),
    calc(var(--ct-secondary-lightness) + 4%)
  );
  border-color: hsl(
    var(--ct-secondary-hue),
    var(--ct-secondary-saturation),
    calc(var(--ct-secondary-lightness) + 6%)
  );
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background-color: hsl(var(--ct-secondary-hue), 7%, 39%);
  border-color: hsl(var(--ct-secondary-hue), 8%, 36%);
  -webkit-box-shadow: 0 0 0 0.2rem hsla(var(--ct-secondary-hue), 6%, 54%, 0.5);
  box-shadow: 0 0 0 0.2rem hsla(var(--ct-secondary-hue), 6%, 54%, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  background-color: hsl(var(--ct-secondary-hue), 8%, 36%);
  border-color: hsl(var(--ct-secondary-hue), 7%, 34%);
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem hsla(var(--ct-secondary-hue), 6%, 54%, 0.5);
  box-shadow: 0 0 0 0.2rem hsla(var(--ct-secondary-hue), 6%, 54%, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: hsl(var(--ct-secondary-hue), 7%, 46%);
  border-color: hsl(var(--ct-secondary-hue), 7%, 46%);
}

/* Outlined buttons */
.btn-outline-primary {
  color: var(--ct-primary);
  border-color: var(--ct-primary);
}

.btn-outline-primary:hover {
  color: var(--ct-white);
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-primary-hue),
      var(--ct-primary-saturation),
      var(--ct-primary-lightness),
      0.5
    );
  box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-primary-hue),
      var(--ct-primary-saturation),
      var(--ct-primary-lightness),
      0.5
    );
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: var(--ct-white);
  background-color: var(--ct-primary);
  border-color: var(--ct-primary);
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-primary-hue),
      var(--ct-primary-saturation),
      var(--ct-primary-lightness),
      0.5
    );
  box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-primary-hue),
      var(--ct-primary-saturation),
      var(--ct-primary-lightness),
      0.5
    );
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--ct-primary);
  background-color: transparent;
}
.btn-outline-secondary {
  color: var(--ct-secondary);
  border-color: var(--ct-secondary);
}
.btn-outline-secondary:hover {
  color: var(--ct-white);
  background-color: var(--ct-secondary);
  border-color: var(--ct-secondary);
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-secondary-hue),
      var(--ct-secondary-saturation),
      var(--ct-secondary-lightness),
      0.5
    );
  box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-secondary-hue),
      var(--ct-secondary-saturation),
      var(--ct-secondary-lightness),
      0.5
    );
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: var(--ct-white);
  background-color: var(--ct-secondary);
  border-color: var(--ct-secondary);
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-secondary-hue),
      var(--ct-secondary-saturation),
      var(--ct-secondary-lightness),
      0.5
    );
  box-shadow: 0 0 0 0.2rem
    hsla(
      var(--ct-secondary-hue),
      var(--ct-secondary-saturation),
      var(--ct-secondary-lightness),
      0.5
    );
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: var(--ct-secondary);
  background-color: transparent;
}

/* Text */
.text-primary {
  color: var(--ct-primary) !important;
}

.text-secondary {
  color: var(--ct-secondary) !important;
}

.text-error {
  text-shadow: hsla(
        var(--ct-primary-hue),
        var(--ct-primary-saturation),
        var(--ct-primary-lightness),
        0.3
      )
      5px 1px,
    hsla(
        var(--ct-primary-hue),
        var(--ct-primary-saturation),
        var(--ct-primary-lightness),
        0.2
      )
      10px 3px;
}

/* Background */
.bg-primary {
  background-color: var(--ct-primary) !important;
}

.bg-primary-mute {
  background-color: hsl(
    var(--ct-primary-hue),
    calc(var(--ct-primary-saturation) - 20%),
    calc(var(--ct-primary-lightness) + 30%)
  );
}

/* Switch Input Primary Color */
input[data-switch]:checked + label {
  background-color: var(--ct-primary);
}
