.image-edit-container {
    position: relative;
    width: 100%;
    max-width: 120px;
    max-height: 120px;
  }
  
  .image-preview {
    width: 100%;
    height: auto;
    border-radius: 4px; 
    transition: opacity 0.3s ease;
    max-height: 120px;
  }
  
  .edit-icon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(255, 255, 255, 0.3); 
  }
  .image-edit-container:hover, .preview-empty:hover{
    cursor: pointer;
  }
  .image-edit-container:hover .image-preview {
    opacity: 0.5;
  }
  
  .image-edit-container:hover .edit-icon-overlay {
    opacity: 1;
  }

  .separator {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #dee2e6;
  }
  .separator::before {
    margin-right: .25em;
  }
  .separator::after {
    margin-left: .25em;
  }
  .btn-check:focus+.btn, .btn:focus {
    box-shadow: 0 0 0 .2rem rgb(255 255 255 / 0%);
}