body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) {
  min-height: 100%;
}
.LinkTree--wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightgray;
}

.LinkTree--container {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.profile_picture img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  background: white;
}
.profile_picture p {
  color: white;
  font-size: 18px;
}
.createQR--form--Linktree--image--circle {
  width: 50px;
  height: 50px;
  overflow: hidden;
  align-content: center;
  margin: auto;
}

.createQR--form--Linktree--imagePreview {
  max-width: 100%;
  height: auto;
}
.LinkTree--links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.LinkTree--link {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
}

.createQR--form--Linktree--image--circle {
  margin-right: 20px;
  flex-shrink: 0;
}

.LinkTree--link span {
  text-align: center;
  flex-grow: 1;
  margin-right: 70px;
}
.customCard {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  padding: 15px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  transition: 0.3s;
}
.customCard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: 0.3s;
  cursor: pointer;
}
.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

@media (max-width: 768px) {
  .customCard {
    width: 90%;
  }
}
