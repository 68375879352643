img.icon {
  background: #e7f9fd;
  padding: 12px;
  width: 50px;
  height: 50px;
  border-radius: 7px;
}
img.icon.small {
  padding: 5px;
  width: 35px;
  height: 35px;
}
.hoverCard {
  border: 2px solid transparent;
  transition: 0.2s ease-in-out;
}

.hoverCard:hover,
.selected {
  border: 2px solid #fd5630;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.createQR--preview img {
  width: 21vw;
}

.createQR--preview img.createQR-switchPreview {
  width: 20px;
}

.input-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.is-invalid {
  border-color: #fa5c7c;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}

.is-invalid-select.css-b62m3t-container .css-13cymwt-control {
  border-color: #fa5c7c;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}

.is-valid {
  border-color: #0acf97;
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}

.invalid-feedback {
  display: block;
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 0.25rem;
}

.input-radio-img {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.radio-img {
  width: 80px;
  opacity: 0.3;
  transition: 0.2s easy-in-out;
}

.radio-img:hover {
  cursor: pointer;
  transition: 0.2s easy-in-out;
  opacity: 1;
}

.active.radio-img:hover svg {
  color: var(--ct-primary);
  fill: var(--ct-primary);
}

.radio-img.active-current {
  color: var(--ct-primary);
  fill: var(--ct-primary);
  opacity: 1;
}

.sticky-preview {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 1000;
  height: 100%;
}

.phone-showcase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align-last: center;
}

.phone-image {
  width: 100%;
  display: block;
}

.qr-preview-container {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 60%;
  margin-top: 6vw;
}

.fixed-col {
  position: fixed;
  top: 0;
  width: 25%;
  z-index: 1000;
}
.fixed-col-container {
  position: relative;
}
.previewQr .grid-container {
  position: fixed;
}

@media (min-width: 992px) {
  .fixed-col {
    max-width: 300px;
  }
}

@media (max-width: 992px) {
  .phone-image {
    display: none;
  }

  .sticky-preview {
    position: static;
  }

  .qr-preview-container {
    position: relative;
    margin-top: 20px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .previewQr .grid-container {
    position: relative;
  }
  .row-content {
    flex-direction: column-reverse;
  }
  .previewQr img {
    min-width: 180px;
  }
  .previewQr canvas {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  img.icon {
    padding: 5px;
    width: 35px;
    height: 35px;
  }
}

.parent-style {
  display: flex; /* Usa Flexbox */
  flex-wrap: wrap; /* Permite que os itens sejam envolvidos */
  width: 100%; /* ou um valor específico */
  height: auto; /* ou um valor específico */
  overflow: hidden; /* Para garantir que o conteúdo não exceda o elemento pai */
}

.container-style {
  display: flex; /* Usa Flexbox */
  flex-wrap: wrap; /* Permite que os itens sejam envolvidos */
  width: 100%; /* Ocupa 100% da largura do elemento pai */
  /* Ajuste o espaçamento entre os itens, se necessário */
}
