.hero-section-sm {
	padding: 80px 0 20px 0;
}
.hero-section-sm::after {
	width: 450% !important;
}
@media screen and (min-width: 992px) {
	.hero-section-sm::after {
		width: 200% !important;
	}
}
