/* This file is used to overwrite hyper's colors */
/* The name of the variables should not be changed */
:root {
  --ct-primary-hue: 11;
  --ct-primary-saturation: 98%;
  --ct-primary-lightness: 59%;
  --ct-primary: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    var(--ct-primary-lightness)
  );

  --ct-secondary-hue: 191;
  --ct-secondary-saturation: 98%;
  --ct-secondary-lightness: 41%;
  --ct-secondary: hsl(
    var(--ct-secondary-hue),
    var(--ct-secondary-saturation),
    var(--ct-secondary-lightness)
  );

  --ct-link-color: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    calc(var(--ct-primary-lightness) + 10%)
  );
  --ct-link-hover-color: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    calc(var(--ct-primary-lightness) + 15%)
  );

  /* Titles color */
  --title-hue: 253;
  --title-saturation: 25%;
  --title-lightness: 14%;

  --ct-body-color: #666276;

  /* This is used in checkbox inputs */
  --ct-component-active-bg: var(--ct-primary);

  /* Used for the select component hover */
  --select-option-hover: #f2f2f2;

  /* Used for brand chart color */
  --chart-android: #3ddc84;
  --chart-ios: #ff3d40;
  --chart-windows: #0078d7;
  --chart-macos: #ff9500;
  --chart-unknown: #9e9e9e;
}
body[data-leftbar-theme="dark"] {
  --ct-primary: hsl(
    var(--ct-primary-hue),
    var(--ct-primary-saturation),
    var(--ct-primary-lightness)
  );

  --ct-component-active-bg: var(--ct-primary);
}
body[data-leftbar-theme="light"] {
  /* This is used in the reserved navigation bar */
  --ct-menu-item-hover: var(--ct-primary);
  --ct-menu-item-active: var(--ct-primary);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  background: var(--ct-primary);
}
