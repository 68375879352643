$blockSize: 2em;
//$blockSize: 6vmin; // Try this in Firefox! Viewport based size, which doesn't work in Canary at the moment

// Play with these to see what you get
$delay: .065s;
$duration: .88s;
// $startingColor: var(--primary);
// $endingColor: var(--secondary);
$startingColor: var(--ct-primary); 
$endingColor: var(--ct-secondary);
$blockMargin: $blockSize / 3;
$loaderSize: $blockSize * 3 + $blockMargin * 2;
@keyframes pulse {
  from, 40% { 
    background: $startingColor;
  }
  to {
    background: $endingColor;
  }
}

@keyframes show {
  from, 40% { opacity: 0; }
  41%, to { opacity: 1; }
}

#loading-component {
  text-align: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $loaderSize;
  height: $loaderSize;
  margin-left: -$loaderSize/2;
  margin-top: -$loaderSize/2;
}

.loader-block {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  opacity: 0;
  width: $blockSize;
  height: $blockSize;
  background: $startingColor;
  animation: show $duration step-end infinite alternate, pulse $duration linear infinite alternate;
}

.loader-block:nth-child(1) {
  transform: translate(0, 0);
  animation-delay: 1 * $delay;
}

.loader-block:nth-child(2) {
  transform: translate($blockSize + $blockMargin, 0);
  animation-delay: 2 * $delay;
}

.loader-block:nth-child(3) {
  transform: translate(($blockSize * 2) + ($blockMargin * 2), 0);
  animation-delay: 3 * $delay;
}

.loader-block:nth-child(4) {
  transform: translate(0, $blockSize + $blockMargin);
  animation-delay: 5 * $delay;
}

.loader-block:nth-child(5) {
  transform: translate($blockSize + $blockMargin, $blockSize + $blockMargin);
  animation-delay: 2 * $delay;
}

.loader-block:nth-child(6) {
  transform: translate(($blockSize * 2) + ($blockMargin * 2), $blockSize + $blockMargin);
  animation-delay: 7 * $delay;
}

.loader-block:nth-child(7) {
  transform: translate(0, ($blockSize * 2) + ($blockMargin * 2));
  animation-delay: 6 * $delay;
}

.loader-block:nth-child(8) {
  transform: translate($blockSize + $blockMargin, ($blockSize * 2) + ($blockMargin * 2));
  animation-delay: 4 * $delay;
}

.loader-block:nth-child(9) {
  transform: translate(($blockSize * 2) + ($blockMargin * 2), ($blockSize * 2) + ($blockMargin * 2));
}

/* Body styling */
#loading-component {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}


