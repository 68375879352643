:root {
	--ct-hero-bg: linear-gradient(
		to bottom,
		hsl(
			var(--ct-primary-hue),
			var(--ct-primary-saturation),
			var(--ct-primary-lightness)
		),
		hsl(
			var(--ct-primary-hue),
			var(--ct-primary-saturation),
			calc(var(--ct-primary-lightness) + 10%)
		)
	);
}

.qr-type-image {
	max-width: 20px;
}

.last-section-image {
	width: clamp(200px, 50%, 300px);
}
